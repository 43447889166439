.entry table { width: 100%; margin: 0; border: 2px solid $borderBlue; border-collapse: separate; }
.entry table:not(:last-child) { margin-bottom: 1.5rem; }
.entry table th { font-weight: 400; font-size: 0.7rem; text-align: left; text-transform: uppercase; padding: 0.7rem 1rem; background: $lightestBlue; color: $gray; }
.entry table td { padding: 0.4rem 1rem 0.55rem 1rem; font-size: 0.8rem; line-height: 1.625; }
.entry table td:not(:last-child), .entry table th:not(:last-child) { border-right: 2px solid $borderBlue; }
.entry table th, .entry table td { border-bottom: 2px solid lighten($borderBlue, 10%); }
.entry table tbody tr:last-of-type, .entry table > tr:last-of-type { th, td { border-bottom: none; } }
.entry table th p, .entry table td p { font-size: 0.7rem; }

table.subjects { border: none; }
table.subjects th { background: none; border-bottom-color: $borderBlue; border-right: none; padding-top: 0; }
table.subjects td:not(:last-child), table.subjects th:not(:last-child) { border-right: none; }
table.subjects td:first-child { border-left: 2px solid $borderBlue; width: 9rem; }
table.subjects td:last-child { border-right: 2px solid $borderBlue; }
table.subjects tbody tr:last-of-type, table.subjects > tr:last-of-type { th, td { border-bottom: 2px solid $borderBlue; } }

table.subjects td { color: $black; }
table.subjects td a { color: $blue; text-decoration: none; }
table.subjects td a { &:hover, &:active { color: $yellow; text-decoration: underline; } }
table.subjects td, table.subjects th { text-transform: none; font-size: 0.9rem; &:nth-child(3), &:nth-child(4) { text-align: center; } }

table.positions td:first-child { width: 12rem; }

@media screen and (max-width: $sm) {
	table.subjects td, table.subjects th { padding: 0.4rem 0.5rem; }
}

@media screen and (max-width: $xs) {
	table.subjects td, table.subjects th { padding: 0.4rem 0.25rem; }
	table.subjects td:first-child { min-width: 4rem; width: auto; }

	table.positions td:first-child { width: auto; }
}
.ol-unsupported {
    display: none
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px
}

.ol-control:hover {
    background-color: rgba(255, 255, 255, .6)
}

.ol-zoom {
    bottom: .5em;
    right: .5em
}

@media print {
    .ol-control {
        display: none
    }
}

.ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: #666666;
    font-size: 1.14em;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: .4em;
    background-color: #ffffff;
    border: none;
    border-radius: 2px
}

.ol-control button::-moz-focus-inner {
    border: none;
    padding: 0
}

.ol-zoom-extent button {
    line-height: 1.4em
}

.ol-control button:focus,
.ol-control button:hover {
    text-decoration: none;
    color: #000000;
}

.ol-zoom .ol-zoom-in {
    border-radius: 2px 2px 0 0
}

.ol-zoom .ol-zoom-out {
    border-radius: 0 0 2px 2px
}
.form-std label { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; margin: 0 0 10px 0; display: block; }
.form-std input.text { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; border: 2px solid $lightGray; padding: 0.55rem 1rem 0.55rem 1rem; width: 100%; }
.form-std input.text:focus { border-color: $yellow; }
.form-std textarea { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; resize: vertical; border: 2px solid $lightGray;  padding: 0.55rem 1rem 0.55rem 1rem; width: 100%; min-width: 100%; max-width: 100%; height: 150px; min-height: 150px; max-height: 300px; }
.form-std select { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; width: 100%; padding: 2px 10px; }
.form-std option { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; padding: 2px 20px; }

.form-std input.text[required="required"] { background-image: url('../img/req.png'); background-position: right center; background-repeat: no-repeat; }

/* Standard */

.form-std .cell-set { margin: 0 0 0 -1.5rem; display: flex; flex-wrap: wrap; }
.form-std .cell { margin: 0 0 1rem 0; padding-left: 1.5rem; position: relative; text-align: left; }
.form-std .cell-input { margin: 0 0 1rem 0; padding-left: 1.5rem; position: relative; text-align: left; }

/* Inline */

.form-std .cell-set-2 .cell { width: 50%; }
.form-std .cell-set-3 .cell { width: 33.3333%; }
.form-std .cell-set-4 .cell { width: 25%; }
.form-std .cell-set-5 .cell { width: 20%; }

/* Search */

.form-search { display: flex; }
.form-search .form-search-input { width: 24rem; border: 2px solid #e1d9c0; font-size: 0.9rem; padding: 0.75rem 0.8rem 0.75rem 0.8rem; }
.form-search .btn { margin-left: 1.2rem; }

/* Filter */

.filter-label { display: inline-block; }
.filter-label:not(:last-child) { margin-bottom: 1em; }
.filter-label input { position: absolute; opacity: 0; pointer-events: none; }
.filter-label label { display: inline-block; font-weight: 500; font-size: 0.9rem; line-height: 1.65; margin-bottom: 0; text-transform: uppercase; color: $blue; border: 2px solid $borderBlue; border-radius: 1.5rem; padding: 0.4rem 1.1rem; }
.filter-label input:checked ~ label { border-color: $yellow; color: $yellow; }
.filter-label input:not(:checked) ~ label { &:hover, &:active { background: $yellow; border-color: $yellow; color: $white; } }

.filter-input:not(:last-child) { margin-bottom: 1.5rem; }
.filter input.filter-search { padding-right: 2.5rem; }

.filter-clear { border-top: 2px solid $lightGray; margin-top: 1.5rem; padding-top: 0.8rem; color: #a3c0de; font-size: 0.9rem; text-transform: uppercase; font-weight: 500; }
.filter-clear-btn { display: inline-block; color: #a3c0de; text-decoration: none; position: relative; padding-left: 1.5rem; }
.filter-clear-btn:hover, .filter-clear-btn:active { color: $yellow; text-decoration: underline; }
.filter-clear .icon { position: absolute; left: 0; top: 0.2rem; }

.filter-search-wrapper { position: relative; }
.filter-search-submit { border: none; background: url('../img/search-yellow.svg') right 0.5rem center no-repeat !important; background-size: 1.5rem 1.5rem !important; position: absolute; right: 0; top: 0; height: 100%; width: 2.5rem; padding: 0 !important; }

/* Checkbox / radio */

.cell-check { position: relative; }
.cell-check-input { position: absolute; opacity: 0; pointer-events: none; }
.cell-check-label { position: relative; padding-left: 1.5rem; line-height: 1.5; padding-top: 0.15em; }
.cell-check-label:before { content: ""; display: block; border: 2px solid $black; position: absolute; left: 0; top: 0; transition: background 0.07s ease-in; }
.cell-check-label:after { content: ""; display: block; position: absolute; left: 0; top: 0; opacity: 0; transition: opacity 0.07s ease-in; }

.cell-check-input[type="checkbox"] ~ .cell-check-label:before { width: 18px; height: 18px; border-radius: 2px; }
.cell-check-input[type="checkbox"] ~ .cell-check-label:after { width: 18px; height: 18px; background: url('../img/checkbox-check.svg') center center no-repeat; background-size: 100% 100%; }
.cell-check-input[type="checkbox"]:checked ~ .cell-check-label:before { background: $black; }
.cell-check-input[type="checkbox"]:checked ~ .cell-check-label:after { opacity: 1; }

.cell-check-input[type="radio"] ~ .cell-check-label:before { width: 20px; height: 20px; border-radius: 50%; }
.cell-check-input[type="radio"] ~ .cell-check-label:after { width: 20px; height: 20px; background: url('../img/radio-check.svg') center center no-repeat; background-size: 12px 12px; }
.cell-check-input[type="radio"]:checked ~ .cell-check-label:after { opacity: 1; }

/* Select */

.fstElement { border: 2px solid $lightGray; width: 100%; }
.fstToggleBtn { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; padding: 0.55rem 2rem 0.55rem 1rem; width: 100%; min-width: 0; }
.fstResults { padding: 0; border: 1px solid $lightGray; }
.fstSingleMode.fstActive .fstResults { box-shadow: 0 3px 4px rgba($black, 0.13); margin-top: -2px; z-index: 1000; max-height: 23rem; }
.fstResultItem { font-weight: 400; font-size: 0.9rem; font-family: $baseFont; line-height: 1.3; color: $baseFontColor; padding: 0.5rem 0.65rem; border: none; }
.fstResultItem.fstFocused { background: $yellow; color: $white; }
.fstResultItem.fstSelected { background: $yellow; color: $white; }
.fstToggleBtn::after { width: 1.8rem; height: 1.8rem; border: none; background: url('../img/chevron-down-blue.svg') center center no-repeat; background-size: 100% 100%; right: 0.35rem; top: 50%; margin-top: -0.9rem; transition: transform 0.07s ease-in; }
.fstElement.fstActive .fstToggleBtn::after { transform: rotateX(180deg); }
.fstElement.fstActive { border-color: $yellow; }
.fstElement.fstActive .fstResults { border-top-color: $yellow; }
.fstElement.fstActive .fstControls { border-color: $yellow; }

.fstElement.fstSingleMode .fstControls { padding: 0; }
.fstElement.fstSingleMode .fstQueryInput { opacity: 0; max-height: 0; padding: 0; pointer-events: none; }


/* typeahead*/

.twitter-typeahead { width: 100%; }
.tt-hint, .tt-input { font-size: 0.9em; font-family: $baseFont; font-weight: 300; color: darken($gray, 15%); border: none; padding: 1em 0.8em 1em 0.8em; width: 100%; line-height: 1.35em; }
.tt-menu { z-index: 3000 !important; background: $white; border-top: 1px solid #D7D7D7; width: 100%; box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.1); }
.tt-suggestion { font-size: 0.9rem; padding: 0.35em 0.75em; display: inline-block; width: 100%; color: $blue; text-decoration: none; }
.tt-suggestion:hover, .tt-suggestion:active { background: $yellow; text-decoration: none; color: $white; }


@media screen and (max-width: $xl) {
	.filter-label label { font-size: 0.8rem; }

	.tt-hint { padding: 1em 0.6em 1em 0.6em; }
}

@media screen and (max-width: $lg) {
	.filter-label:not(:last-child) { margin-bottom: 0.75em; }
	.filter-label label { font-size: 0.7rem; padding: 0.4rem 1rem; }
}

@media screen and (max-width: $sm) {
	.form-search { flex-wrap: wrap; }
	.form-search .form-search-input { margin-bottom: 0.75em; margin-right: 0; width: 100%; }
}
.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; }
.slider ul { display: flex !important; justify-content: stretch; }
.slider li.panel { display: block; }
.slider, .slider li.panel { overflow: hidden; background: none; }

.slider-nav { display: block; text-align: center; width: 100%; }
.slider-nav ol { margin: 0 0 0 -10px; }
.slider-nav li { margin: 0 0 0 10px; padding: 0; background: none; display: inline-block; font-size: 0; }
.slider-nav li:before { content: none; }
.slider-nav li a { width: 8px; height: 8px; display: block; background: #2f4e75; text-indent: -80000px; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.slider-nav li.current a { background: #b8bdc5; }

.slider-nav .prev, .slider-nav .next { position: absolute; top: 50%; transform: translateY(-50%); display: inline-block; width: 3.25em; height: 2.5em; display: block; background-size: 1.3rem 1rem; background-color: $white; background-position: center center; background-repeat: no-repeat; }
.slider-nav .prev { left: 0; background-image: url('../img/arrow-left-blue.svg'); }
.slider-nav .prev:hover, .slider-nav .prev:active { background-color: $yellow; background-image: url('../img/arrow-left-white.svg'); }
.slider-nav .next { right: 0; background-image: url('../img/arrow-right-blue.svg'); }
.slider-nav .next:hover, .slider-nav .next:active { background-color: $yellow; background-image: url('../img/arrow-right-white.svg'); }

.slider-alt .slider-nav { .prev, .next { background-color: $lightBlue; } }

.item-slider { overflow: hidden; }
.item-slider .slider { margin: 0 -0.75rem; }
.item-slider li.panel .item { padding: 0 0.75rem; height: 100%; }

/* Gallery slider */

.gallery-slider { position: relative; }
.gallery-slider li.panel:not(.slide):not(:nth-child(-n+2)) { display: none; }
.gallery-slider figure img { width: 100%; }

/* Logo slider */

.logo-slider { position: relative; }
.logo-slider .item-inner { height: 100%; border: 2px solid $lightBlue; padding: 0.5rem 1rem; display: flex; justify-content: center; align-items: center; }
.logo-slider li.panel { width: 25%; }
.admin-text { font-family: "Open Sans", "Helvetica Neue", helvetica, arial, verdana, sans-serif; font-size: 13px !important; color: #606060; }

img.x-form-trigger { display: inline !important; }
div.pimcore_tag_multihref { background-color: #fff; }
.ib script { display: none !important; }
table.x-btn { line-height: normal !important; margin: 0 !important; padding: 0 3px 0 3px !important; }
table.x-btn td { line-height: normal !important; margin: 0 !important; padding: 0 !important; }
table.x-btn tr { border: none !important; }
.x-menu-list-item { line-height: normal !important; }

.pimcore_area_buttons_inner { display: flex; }
.pimcore_area_buttons { visibility: visible !important; }
.pimcore_area_buttons .pimcore_block_label { white-space: nowrap; }
.pimcore_editable.btn { border: none !important; background: none !important; }
.pimcore_editable.link { padding: 0 30px 0 0 !important; margin: 0 !important; }
.pimcore_editable.link .x-btn { z-index: 10; }
#pimcore_editable_numbers { width: 30%; }

.topic .pimcore_wysiwyg { width: 100% !important; }

.topic-content .general-items .pimcore_tag_areablock { width: 33.33333%; }
.boxes-check-list .pimcore_tag_areablock { width: 50%; }

.box-half-img .pimcore_tag_image { height: 100%; }

.columns .pimcore_tag_areablock { width: 100%; }

.timeline.editmode .timeline-item { justify-content: flex-start !important; }
.timeline .pimcore_tag_image { z-index: 10; }

/*========================= Editor =========================*/

.pimcore_editable { line-height: normal !important; }
.pimcore_area_entry { margin: 0 !important; padding: 0 !important; }

.entry .pimcore_iframe_mask, .perex .pimcore_iframe_mask { display: none; }

.tox-collection__item-label { p, h1, h2, h3, h4, h5, h6 { color: #676d79 !important; } }
.tox-collection__item-label span { color: #676d79 !important; }
.tox-collection__item-label a { color: #0071bb !important; }
.tox-collection__item-label a { color: #0071bb !important; }
[title~="výplň"] .tox-collection__item-label a { color: #fff !important; }
.tabs { position: relative; }

.tab-nav ul { margin: 0; padding: 0; display: flex; border-bottom: 2px solid $yellow; }
.tab-nav li { list-style-type: none; padding: 0; margin: 0; background: none; }
.tab-nav li:not(:last-child) { padding-right: 3px; }
.tab-nav li a { display: block; height: 100%; position: relative; text-decoration: none; outline: 0; font-weight: 500; font-size: 0.8rem; padding: 0.75rem 0.9rem; background: $darkerBlue; color: $white; transition: all 0.07s ease-in; }
.tab-nav li a { &:hover, &:active { background: $yellow; color: $white; } }
.tab-nav li a.selected { background: $yellow; }
.tab-nav li a.selected:after { @include triangleBottom(14px, 7px, $yellow); position: absolute; left: 50%; bottom: -7px; transform: translateX(-50%); }

.tab-nav-full li { flex: 1; }

.tab-nav-light li a { background: $lightBlue; color: $blue; }
.tab-nav-light li a.selected { background: $lightYellow; color: $black; }

.tab-content .tab { float: left; width: 100%; display: none; }
.tab-content .tab.selected { position: static !important; display: block; }

// Tabs in column head

.tabs-column .tab-nav ul { border: none; }
.tabs-column .tab-nav ul li:not(:last-child) { padding-right: 1rem; }
.tabs-column .tab-nav ul li a { background: none; padding: 0; color: $blue; position: relative; }
.tabs-column .tab-nav ul li a:after { content: ""; display: block; width: 100%; height: 2px; background: $yellow; border: none; position: absolute; bottom: -2px; left: 0; transform: scaleX(0); }
.tabs-column .tab-nav ul li a { &:hover, &:active { background: none; color: $yellow; text-decoration: underline; } }
.tabs-column .tab-nav ul li a.selected { background: none; color: $black; }
.tabs-column .tab-nav ul li a.selected:after { transform: scaleX(1); }
.tabs-column .tab-nav .column-head { color: inherit; }

.tabs-column .tab-content .tab { padding: 0; }

// Homepage tabs

.tabs-hp .tab-nav { margin-bottom: 1.5rem; }
.tabs-hp .tab-nav li { flex-grow: 1; }
//.tabs-hp .tab-nav li a { background: $lightBlue; color: $blue; text-align: center; }
.tabs-hp .tab-nav li a { background: $lightBlue; color: $blue; text-align: center; font-size: 0.85rem; text-transform: uppercase; font-weight: 700; padding: 0.65rem 0.5rem 0.55rem 0.5rem; }
.tabs-hp .tab-nav li a.selected { background: $lightYellow; color: $black; }


/*.tabs-hp .tab-nav { margin-bottom: 1.5rem; }
.tabs-hp .tab-nav ul { border: none; }
.tabs-hp .tab-nav li { flex-grow: 1; padding-right: 0 !important; margin-left: -2px; }
.tabs-hp .tab-nav li a { background: #f1f4f7; color: $blue; border: 2px solid $lightGray; text-align: center; font-size: 0.9rem; text-transform: uppercase; font-weight: 700; padding: 0.65rem 0.5rem 0.55rem 0.5rem; }
.tabs-hp .tab-nav li a.selected { background: $white; border-bottom-color: transparent; color: $black; }
.tab-nav li a.selected:after { display: none; }*/
.footer { background: $lightBlue; padding: 3rem 0 1.6rem 0; }

.footer-head { font-weight: 700; font-size: 0.9rem; text-transform: uppercase; color: $black; margin-bottom: 0.8rem; }
.footer-head .icon { fill: $yellow; width: 1rem; height: 1rem; transform: translateX(-0.15rem); }

.footer-nav { margin-bottom: 1.5em; }
.footer-nav li { padding-left: 0; margin-bottom: 0.2rem; }
.footer-nav li a { color: $blue; text-decoration: none; font-size: 0.9rem; font-weight: 400; letter-spacing: 0.008rem; }
.footer-nav li a { &:hover, &:active, &:focus { color: $yellow; text-decoration: underline; } }
.footer-nav li a .icon { width: 0.85rem; height: 0.85rem; transform: translate(-0.25rem, 0.42rem); }

.footer-contact { color: $gray; font-weight: 400; }
.footer-contact a { color: $blue; text-decoration: none; }
.footer-contact a { &:hover, &:active { color: $yellow; text-decoration: underline; } }
.footer-contact p { margin-bottom: 0.8rem; }

.footer-btns { margin-top: 1.6rem; }
.footer-btns li { padding: 0; margin-bottom: 1rem; }

.footer-soc-nets { margin-top: 0.75rem; }
.footer-soc-nets .icon { margin: 0 0.5rem 0 0; }

.footer-sub { border-top: 2px solid #cee4f2; padding-top: 1.5rem; margin-top: 2.5rem; color: $gray; font-size: 0.9rem; }
.footer-sub p { margin-bottom: 0; display: inline-block; }
.footer-sub a { color: $blue; }

.porta { width: 69px; height: 15px; display: block; float: right; color: $blue; fill: $blue; margin-top: 0.15rem; }
.porta:hover, .porta:active, .porta:focus { color: $yellow; fill: $yellow; }

@media screen and (max-width: $xl) {
	.footer-nav li a .icon { transform: translate(-0.25rem, 0.52rem); }
}
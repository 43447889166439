.content { padding: 0; }

/* Breadcrumbs */

.breadcrumbs { padding: 0.7rem 0 0 0; background: $blue; position: relative; z-index: 100; margin-bottom: -1rem; }
.breadcrumbs ul { display: flex; flex-wrap: wrap; margin: 0; }
.breadcrumbs ul li { font-size: 0.7rem; color: rgba($white, 0.6); position: relative; padding-left: 0; }
.breadcrumbs ul li:not(:first-child) { padding-left: 1.8rem; }
.breadcrumbs ul li:not(:first-child):before { content: ""; display: block; width: 0.9rem; height: 0.9rem; background: url('../img/arrow-white.svg') center center no-repeat; background-size: 100% 100%; position: absolute; left: 0.5rem; top: 0.15em; }
.breadcrumbs ul li:last-child:before { opacity: 0.6; }
.breadcrumbs ul li a { color: $white; text-decoration: none; &:hover, &:active, &:focus { text-decoration: underline; }}

/* Block */

.block { padding: 2.8rem 0; }
.block.block-narrow { padding: 1.5rem 0; }

.block.block-alt { background: $lightYellow; }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }

.block.block-bg { position: relative; padding: 3.5rem 0; min-height: 50vw; color: $white; background-color: $lightBlue; }

.block-bg-img {  @include bgImageWrapper(); position: absolute; left: 0; top: 0; width: 100%; height: 100%; }
.block-bg-img img { display: block; width: 100%; position: absolute; left: 50%; top: 0; transform: translateX(-50%); }

.block-bg-box { padding: 2rem 2.35rem 2.25rem 2.35rem; position: relative; }
.block-bg-box > a { text-decoration: none; }
a.block-bg-box { display: block; text-decoration: none; }
a.block-bg-box:hover, a.block-bg-box:active, .block-bg-box > a:hover, .block-bg-box > a:active { .block-bg-box-title { text-decoration: underline; } .btn { @extend %btnHover; } }
.block-bg-box-title { font-size: 1.8rem; line-height: 1.35; margin-bottom: 0.5em; }
.block-bg-box-number { position: absolute; display: inline-block; left: 2.5rem; top: 0; transform: translateY(-50%); background: $yellow; color: $white; padding: 0.5rem 0.9rem 0.5rem 0.7rem; font-size: 2rem; color: $white; font-weight: 700; }

.block-bg-box-blue { background: $blue; color: rgba($white, 0.92); &:hover, &:active, &:focus { color: rgba($white, 0.92); } }
.block-bg-box-blue { h2, .h2, h3, .h3, h4, .h4, .block-bg-box-title { color: $white; } }

.block-bg .text-line { display: inline-block; }

.block-bg-reasons { display: flex; align-items: center; }
.block-bg-reasons .block-bg-box { padding-top: 3rem; }

.block-last { padding-bottom: 3.5rem; }

.content-shift { margin-top: -7.5rem; position: relative; z-index: 5; }
.content-shift-space, .teaser.content-shift-space { padding-bottom: 5.75rem; }
.teaser-bg.content-shift-space { padding-bottom: 6.75rem; }

.content-shift-small { margin-top: -4.35rem; }
.content-shift-small-space, .teaser.content-shift-small-space { padding-bottom: 2.35rem; }

.section-head { text-align: center; }

.page-note { font-size: 0.9em; color: $gray; padding-top: 1.35em; border-top: 2px solid #d1d9e6; }
.page-note-noborder { border: none; }
.page-note p { margin-bottom: 1.45em; }
.page-note a { color: $blue; &:hover, &:active, &:focus { color: $yellow; text-decoration: underline; } }

/* HP Banners */
.block-hp-banners {
  padding: 24px 0;
}
.hp-banners {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  height: 600px;
}
.hp-banner {
  position: relative;
  overflow: hidden;
  grid-column: span 4;
  grid-row: span 1;
}
.hp-banner:first-child {
  grid-column: 1 / 7;
  grid-row: 1 / 3;
  .hp-banner-content {
    padding: 40px;
    max-width: 520px;
  }
}
.hp-banner-link, .hp-banner-content-wrapper {
  display: block;
  text-decoration: none;
  color: $white;
  height: 100%;
}
.hp-banner-image {
  position: relative;
  height: 100%;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
}
.hp-banner-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: inline-block;
  padding: 20px;
  background-color: $blue;
  transition: background-color 0.3s ease;
  max-width: 290px;
}
.hp-banner-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.hp-banner-description {
  font-size: 18px;
  line-height: 1.5;
}
.hp-banner-button {
  margin-top: 1rem;
}
.hp-banner-link:hover,
.hp-banner-link:focus {
  .hp-banner-image img {
    transform: scale(1.05);
  }
  .hp-banner-content {
    color: $white;
    background-color: rgba($yellow, 0.9);
  }
}

/* Teaser */

.teaser { background: $teaserBlue; color: $white; position: relative; padding-bottom: 2rem; }
.teaser-hp { min-height: 23.5rem; }

.teaser .btn { @extend .btn-light; }
.teaser .btn:hover, .teaser .btn:active, .teaser .btn:focus { @extend %btnHover; }

.teaser-news { display: block; text-decoration: none; padding-left: 1.75rem; }
.teaser-news.news .item-date { color: $white; padding-left: 1.7rem; }
.teaser-news.news .item-title { color: $white; margin-bottom: 0.4em; font-size: 2.4rem; font-weight: 700; }
.teaser-news.news a { text-decoration: none; }
.teaser-news.news a { &:hover, &:active, &:focus { .item-title { text-decoration: underline; } } }
.teaser-news.news .item-text { margin-bottom: 0.9em; color: rgba($white, 0.92); }
.teaser-news .btn { margin-top: 0.75rem; }

.teaser-img-overflow { position: absolute; height: 100%; overflow: hidden; }
.teaser-img-overflow img { min-width: 100%; min-height: 100%; width: auto; height: auto; max-width: none; }
.teaser-img-half { width: 50%; top: 0; left: 0; }
.teaser-img-overflow:after { @include triangleTopRight(3rem, 3rem, $blue); position: absolute; right: 0; top: 4rem; }

.teaser h1:last-child { margin-bottom: 0.2em; }
.teaser.content-shift-space, .teaser.content-shift-small-space { h1:last-child { margin-bottom: 0.6em; } }

.teaser-breadcrumbs { padding-top: 0; }
.teaser-breadcrumbs .breadcrumbs { background: none; margin-bottom: 3rem; }
.teaser-breadcrumbs:not(.teaser-bg) .breadcrumbs { margin-bottom: 1.8rem; }

.teaser-bg { position: relative; padding-bottom: 7.75rem; }
.teaser-bg:after { content: ""; display: block; width: 100%; height: 100%; background: linear-gradient(to top, rgba($blue,0) 0%, rgba($blue,0) 60%, rgba(#1769c5,0.7) 100%); position: absolute; left: 0; top: 0; z-index: 2; }
.teaser-bg .container { z-index: 5; position: relative; }
.teaser-bg-img { position: absolute; left: 0; top: 0; overflow: hidden; width: 100vw; height: 100%; z-index: 1; }
.teaser-bg-img img { position: absolute; left: 50%; top: 0; transform: translateX(-50%); width: auto; max-width: none; min-width: 100%; min-height: 100%; }

.teaser-bg-overlay { padding-bottom: 2.8rem; }
.teaser-bg-overlay:after { background: rgba($blue, 0.9); }
.teaser-bg-overlay .teaser-bg-img img { filter: contrast(150%); }

.teaser-bg-head { margin-bottom: 2rem; margin-right: -2rem; padding-left: 0.9rem; padding-top: 0.25rem; }
.teaser-bg-head h1:not(:last-child), .teaser-head h1:not(:last-child) { margin-bottom: 0; }
.teaser-bg-subhead { font-size: 2.4rem; line-height: 1.25; font-weight: 300; white-space: pre-line; margin-bottom: 0; }
.teaser-subhead { color: $white; font-size: 2.4rem; line-height: 1.25; font-weight: 300; margin-bottom: 1rem; }
.teaser-bg-head h1, .teaser-bg-subhead { color: $white; display: inline; background: $blue; padding: 0.25rem 0 0.6rem 0; box-shadow: 0.75rem 0 0 $blue, -0.9rem 0 0 $blue; box-decoration-break: clone; transition: $hoverTransition; }

.teaser-details { display: flex; padding-left: 2px; margin-top: 1rem; }
.teaser-detail { flex: 1; border: 2px solid #338bc7; border-bottom: none; padding: 1rem 1.25rem 1.15rem 1.25rem; color: $white; font-size: 0.9rem; margin-left: -2px; }
.teaser-detail-head { color: $lightTextBlue; font-size: 0.9rem; font-weight: 500; text-transform: uppercase; margin-bottom: 0.2rem; }
.teaser-detail p { margin-bottom: 0; }
.teaser-detail p .icon { width: 0.9rem; height: 0.9rem; margin: 0.4rem 0 0 0.3rem; }
.teaser-detail .item-label { display: inline-block; text-decoration: none; margin: 0.5rem 0.5rem 0 0; border: 2px solid #338bc7; padding: 0.15rem 0.75rem 0.3rem 0.75rem; font-size: 0.8rem; font-weight: 700; border-radius: 1rem; white-space: nowrap; }
.teaser-detail a.item-label { &:hover, &:active, &:focus { background: $yellow; border-color: $yellow; color: $white; } }

.subsite-nav { width: auto; margin-bottom: 1.5rem; display: inline-block; }
.subsite-nav > ul { @extend .list-unstyled; margin: 0; display: flex; align-items: stretch; box-shadow: 0px 4px 6px 0px rgba($black, 0.15); }
.subsite-nav li { padding: 0; margin: 0; position: relative; }
.subsite-nav-item { height: 100%; @extend .btn; @extend .btn-fill;  line-height: 1.2; display: flex; align-items: center; font-size: 0.8rem; padding: 0.4rem 0.9rem; border: none; background-color: $lighterBlue; min-height: 3rem; }
.subsite-nav-item:not(.subsite-nav-parent) { text-transform: none; padding-right: 0.9rem; background-image: none; &:hover, &:active, &:focus { padding-right: 0.9rem; background-image: none; } }
li:not(:first-child) .subsite-nav-item { border-left: 2px solid $darkBorderBlue; &:hover, &:active, &:focus { border-color: darken($yellow, 5%); } }
.subsite-nav-parent { padding-right: 2.75rem; font-weight: 700; }
.subsite-nav-current, .teaser-bg .subsite-nav-item.subsite-nav-current { background-color: $yellow; border-color: darken($yellow, 5%) !important; }

.subsite-nav-dropdown-trig:after { content: ""; display: inline-block; width: 1rem; height: 1rem; min-width: 1rem; opacity: 0.6; background: url('../img/chevron-down-white.svg') center center no-repeat; background-size: 100% 100%; transform: translate(0.15rem, 0.05rem); transition: transform 0.1s ease-in; }
.subsite-nav-dropdown-trig.dropdown-trig-active:after { transform: translate(0.15rem, 0.05rem) rotateX(180deg); }

.subsite-nav-dropdown { @extend .list-unstyled; box-shadow: 0px 4px 6px 0px rgba($black, 0.12); margin: 0 0 0 2px; position: absolute; left: 0; top: calc(100% + 10px); z-index: 500; display: none; background: $lightYellow; padding: 1rem 1.35rem; }
.subsite-nav-dropdown:before { @include triangleTop(18px, 8px, $lightYellow); position: absolute; left: 2rem; top: -8px; }
.subsite-nav-dropdown li { margin-bottom: 0.25rem; }
.subsite-nav-dropdown li:last-child { margin-bottom: 0; }
.subsite-nav-dropdown-item { color: $blue; font-weight: 400; letter-spacing: 0.008rem; font-size: 0.9rem; text-decoration: none; line-height: 1.8; display: block; white-space: nowrap; }
.subsite-nav-dropdown-item { &:hover, &:active, &:focus { color: $yellow; text-decoration: underline; } }
.subsite-nav-dropdown-item-head { font-weight: 700; font-size: 1rem; text-transform: uppercase; border-bottom: 2px solid #e1d9c0; padding-bottom: 0.35rem; margin-bottom: 0.75rem; }
.subsite-nav-dropdown-item-head .icon { margin: 0.35rem 0.25rem 0 0.35rem; }

.teaser-bg .subsite-nav-item { background-color: $blue; }
.teaser-bg .subsite-nav { margin-bottom: 2rem; }

.teaser-omega { padding-bottom: 0; }
.teaser-omega .subsite-nav { margin-bottom: 0; }

.teaser-img-head { font-weight: 300; }
.teaser-img-head img { max-height: 1.9rem; display: inline-block; margin-right: -0.25rem; }

.teaser-bg-head .teaser-img-head img { transform: translateY(0.1rem); }

.teaser-contact-list { font-size: 0.9rem; color: $white !important; }
.teaser-contact-list li, .teaser-contact-list.contact-list li { padding-left: 1.75rem; }
.teaser-contact-list .icon, .teaser-contact-list.contact-list .icon { color: $yellow; width: 1.3rem; height: 1.3rem; margin-top: 0.1rem; }
.teaser-contact-list a, .teaser-contact-list.contact-list a { color: $white; }

.teaser-head-img { margin-top: 0.6rem; }
.teaser-head-img-abs { position: absolute; }

.teaser-prev, .teaser-next { margin-top: 0.6rem; font-size: 0.75rem; line-height: 1.33; color: $lightTextBlue; font-weight: 500; display: block; text-decoration: none; }
.teaser-prev, .teaser-next { &:hover, &:active, &:focus { .btn-prev { @extend %btnPrevHover; } .btn-next { @extend %btnHover; } } }
.teaser-prev, .teaser-next { .btn { margin-bottom: 0.6rem; } }
.teaser-next { text-align: right; }

/* Cta */

.cta { background: $blue; color: $white; padding: 3.5rem 0; }
.cta-content { display: flex; justify-content: space-between; align-items: center; }
.cta-head { margin-bottom: 0; color: $white; font-size: 2rem; position: relative; }
.cta-dots { content: ""; height: 5px; border-top: 5px dotted $yellow; flex: 1; margin: 1px 2rem 0 1.75rem; }

/* Homepage */

.column-head-wrap { border-bottom: 2px solid $lightGray; margin-bottom: 1.5rem;  margin-top: 0.65rem; }
.column-head-wrap .icon { margin-top: 0.15rem; }
.column-head { font-size: 1rem; font-weight: 700; text-transform: uppercase; line-height: 1.5; padding-bottom: 0.6em; margin-bottom: 0; }

.fields-box { background: $lightYellow; padding: 2.75rem 3.5rem 3.5rem 3.5rem; }
.fields-box .entry { color: $yellowBlack; font-size: 0.9rem; height: 100%; display: flex; flex-direction: column; justify-content: flex-start; }

.fields-list { margin-bottom: 0; }
.fields-list li { margin-bottom: 1rem; padding: 0; }
.fields-list li:last-child { margin-bottom: 0; }
.fields-list .btn { width: 100%; font-size: 1.2rem; text-transform: none; padding: 1.5rem 5rem 1.5rem 2rem; background-size: 1.8rem 1.4rem; background-position: right 1.7rem center; }
.fields-list .btn { &:not(:hover):not(:active):not(:focus) { background-image: url('../img/arrow-yellow.svg'); } }

.success-stories { margin: 0 0 0 -0.25rem; }
.success-stories .item { width: 50%; padding-left: 0.25rem; margin-bottom: 0.25rem; }
.success-stories .item-inner { position: relative; overflow: hidden; }
.success-stories .item-inner:after { content: ""; display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8) 95%, rgba(0,0,0,0.8) 100%); z-index: 1; }
.success-stories .item-img { transform: scale(1); transition: all 0.1s ease-in; transform-origin: 50% 50%; }
.success-stories .item-inner { &:hover, &:active, &:focus { .item-img { transform: scale(1.08); transition: all 0.1s ease-out; } } }
.success-stories .item-text { position: absolute; left: 0; bottom: 0; padding: 1.5rem; max-width: 400px; z-index: 5; }
.success-stories .item-title { margin-bottom: 0; font-size: 1.2rem; font-weight: 500; }

.success-stories .item-no-img .item-text { position: relative; }
.success-stories .item-no-img .item-inner { background: $lightBlue; }
.success-stories .item-no-img .item-inner:after { display: none; }

.item-title-hl { font-weight: 500; color: $white; line-height: 1.4; display: inline; background: $blue; padding: 0.25rem 0 0.35rem 0; box-shadow: 0.4rem 0 0 $blue, -0.4rem 0 0 $blue; box-decoration-break: clone; transition: $hoverTransition; margin-left: 0.4rem; }
a.item-inner:hover, a.item-inner:active, a.item-inner:focus { .item-title-hl { background-color: $yellow; box-shadow: 0.4rem 0 0 $yellow, -0.4rem 0 0 $yellow; } }
.item-title-hl-small { font-size: 0.9rem; line-height: 1.6; padding: 0.3rem 0 0.45rem 0; box-shadow: 0.6rem 0 0 $blue, -0.6rem 0 0 $blue; margin-left: 0.6rem; }

/* Bachelor's study */

.stories { margin-bottom: -1.5rem; }
.stories .item { margin-bottom: 1.5rem; width: 25%; }
.stories .item-img img { width: 100%; }
.stories .item-inner { position: relative; }
.stories .item-text { position: absolute; left: 0; bottom: 0; padding: 1.8rem 1.5rem; }
.stories .item-status { font-weight: 400; margin-top: -0.15rem; }

/* Article */

.article > .row:not(:first-child) { margin-top: 2.8rem; }
.article .entry { color: $blueGray; }
.block-alt .article .entry { color: $yellowBlack; }
.article .entry { h1, h2, h3, h4, h5, h6 { color: $black; } }

.columns .btn { padding-right: 2.75rem; }
.columns-2 { margin-bottom: -1.25rem; }
.columns-2 .column { margin-bottom: 1.25rem; }

.img-set { display: flex; flex-wrap: wrap; margin-left: -1.5rem; margin-bottom: -1.5rem; }
.img-set:last-of-type { margin-bottom: -1.5rem; }
.img-set figure { padding-left: 1.5rem; margin-bottom: 1.5rem; }
.img-set-2 figure { width: 50%; }

.box { background: $lightBlue; }
.box.box-alt { background: $lightYellow; }
.box-content { padding: 1.5rem 2.25rem 1.75rem 2.25rem; color: $blueGray; }
.box-content { h2, h3, h4, h5, h6 { color: $black; } }
.box-content .btn { padding-right: 3rem; }

.box-half-img { display: flex; align-items: stretch; }
.box-half-img .box-img { order: 2; width: calc(50% - 15px); position: relative; overflow: hidden; }
.box-half-img .box-img img { position: absolute; min-width: 100%; min-height: 100%; max-width: none; width: auto; left: 50%; top: 0; transform: translateX(-50%); }
.box-half-img .box-content { order: 1; padding: 2.75rem 2.5rem 3rem 3.25rem; width: calc(50% + 15px); color: $black; }

.box-half-img-reverse .box-img { order: 1; }
.box-half-img-reverse .box-content { order: 2; }

.numbered-block { position: relative; overflow: hidden; min-height: 30.25rem; }
.numbered-block-noimg { min-height: 0; }
.numbered-block-img { position: absolute; z-index: 1; overflow: hidden; width: 100%; height: 100%; }
.numbered-block-box { position: relative; z-index: 5; width: 50%; background: $white; }
.numbered-block-text { position: relative; }
.numbered-block-number { position: absolute; right: calc(100% + 0.8rem); top: 0; display: inline-block; color: $yellow; font-weight: 700; font-size: 2rem; line-height: 1.3; }

.numbered-block-left .numbered-block-box { padding: 1.5rem 2.5rem 2.25rem 2.75rem; }
.numbered-block-right .numbered-block-box { padding: 1.5rem 0 2.25rem 5.15rem; margin-left: auto; }

.links { border-right: 2px solid $borderBlue; border-left: 2px solid $borderBlue; }
.link { position: relative; display: block; padding: 0.5rem 1rem; border-radius: 1px; border-bottom: 2px solid lighten($borderBlue, 10%); background: $white; text-decoration: none; color: $blue; font-size: 0.9rem; transition: none; }
.link:first-child { border-top: 2px solid $borderBlue; }
.link:last-child { border-bottom: 2px solid $borderBlue; }
a.link:hover, a.link:active, a.link:focus { color: $white; background: $yellow; border-color: $yellow; margin: 0 -2px; padding: 0.5rem calc(1rem + 2px); &:after { content: ""; display: block; width: 100%; height: 2px; background: $yellow; position: absolute; left: 0; top: -2px; }}
.link-icon { width: 1.2rem; height: 1.2rem; color: $yellow; position: absolute; right: 0.75rem; top: 50%; transform: translateY(-50%); }
.link-icon.icon-external { width: 1rem; height: 1rem; }
a.link:hover, a.link:active, a.link:focus { .link-icon, .link-download-icon { color: $white; right: calc(0.75rem + 2px); } }
div.link { color: $baseFontColor; }

.link-note { display: block; color: $gray; font-size: 0.8rem; margin-top: -0.15rem; }
a.link:hover, a.link:active, a.link:focus { .link-note { color: $white; } }

.link-download { padding-left: 2.35rem; padding-right: 2.35rem; }
a.link-download:hover, a.link-download:active, a.link-download:focus { padding: 0.5rem calc(2.35rem + 2px) 0.5rem calc(2.35rem + 2px); .link-download-icon { left: calc(0.8rem + 2px); } }
.link-download-icon { color: $red; position: absolute; left: 0.8rem; top: 50%; transform: translateY(-50%); width: 1.3rem; height: 1.3rem; }
.link-download-ext { font-size: 0.8rem; }

.link-sub { padding-left: 2.5rem; }
a.link-sub:hover, a.link-sub:active, a.link-sub:focus { padding: 0.5rem calc(1rem + 2px) 0.5rem calc(2.5rem + 2px); .link-sub-icon { left: calc(0.8rem + 2px); color: $white; } }
.link-sub-icon { color: #a3c0de; position: absolute; left: 0.8rem; top: 50%; transform: translateY(-50%); width: 1.3rem; height: 1.3rem; }

.links-more { transform: translateY(-2px); }
.links-more .link:first-child { border-top: 2px solid lighten($borderBlue, 10%); }



.contact { background: $lightBlue; display: flex; }
.contact:not(:last-child) { margin-bottom: 1rem; }
.contact-img { flex: 0 0 9rem; }
.contact-content { flex: 1; padding: 1.5rem 1.25rem 1.25rem 0; font-size: 0.9rem; display: flex; }
.contact-content-col:nth-child(1) { width: 45%; padding-left: 1.75rem; }
.contact-content-col:nth-child(2) { width: 55%; padding-left: 1.75rem; }
.contact-name, .article .entry .contact-name { color: $blue; font-size: 1.1rem; font-weight: 700; margin-bottom: 0.25rem; }
.contact .contact-position { @extend .text-line; font-size: 0.7rem; text-transform: uppercase; }
.contact-list { @extend .list-unstyled; color: $gray; font-weight: 500; }
.contact-list li { margin-bottom: 0.4rem; white-space: nowrap; position: relative; padding-left: 1.5rem; }
.contact-list a { @extend .reverse-underline; color: $blue; }
.contact-list .icon { margin: 0.2rem 0.2rem 0 0; position: absolute; left: 0; top: -0.1rem; }
.contact-list .icon.icon-email { top: -0.05rem; }

.article .contacts:not(:last-child) { margin-bottom: 1.25rem; }

.contacts-wrap > .row { margin-top: 1.5rem; }

.accordion, .step { border: 2px solid $borderBlue;  border-radius: 1px; padding-bottom: 0.55rem; background: $white; }
.accordion, .step { &:not(:last-child) { margin-bottom: 1rem; } }
.accordion-head, .step-head { display: block; padding: 1.1rem 3.5rem 0.7rem 1.5rem; position: relative; }
.accordion-head.sh-trig, .step-head.sh-trig { &:hover { cursor: pointer; } &:hover, &:active, &:focus { .accordion-title, .step-title { color: $yellow; text-decoration: underline; } } }
.accordion-head.sh-trig, .step-head.sh-trig { &.active .icon { transform: rotateX(180deg); } }
.accordion-head .icon, .step-head .icon { color: $yellow; position: absolute; right: 0.8rem; top: 1rem; width: 1.8rem; height: 1.8rem; transition: transform 0.07s ease-in; }
.accordion-title, .step-title { font-size: 1.2rem; color: $blue; font-weight: 700; margin: 0; position: relative; }
.accordion-content { padding: 0 1.5rem 0.5rem 1.5rem; }
.step-content { padding: 0 1.5rem 0.5rem 1.75rem; }
.accordion-subhead { padding: 0 3.5rem 0.15rem 1.5rem; margin-top: -0.15rem; }

.article .accordions, .article .steps { &:not(:last-child) { margin-bottom: 1.25rem; } }

.step-head { padding-left: 3.5rem; }
.step-number { color: $yellow; position: absolute; left: 1.5rem; top: 0.9rem; font-size: 1.2rem; font-weight: 700; }
.step-subhead { padding: 0 3.5rem 0.15rem 3.5rem; }

.video figure { position: relative; }
.video figure video { width: 100%; margin: 0 auto; }
.video-play { display: inline-block; width: 5.5rem; height: 3.75rem; background: $blue; color: $white; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); text-align: center; padding: 0.3rem; }
.video-play:hover, .video-play:active, .video-play:focus { background: $yellow; color: $white; }
.video-play .icon { width: 3.2rem; height: 3.2rem; }

.video-iframe { position: relative; }
.video-iframe figure { overflow: hidden; padding-top: 56.25%; position: relative; }
.video-iframe figure iframe { border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%; }

.box-link { display: block; text-decoration: none; padding: 1.25rem 1.5rem; border: 2px solid $borderBlue; border-radius: 1px; background: $white; }
.box-link:not(:last-child) { margin-bottom: 1rem; }
.box-link-title, .article .entry .box-link-title { @extend .h3; color: $blue; margin-bottom: 0.75rem; }
.box-link:hover, .box-link:active, .box-link:focus { .box-link-title { text-decoration: underline; color: $yellow; } }

.addresses { margin-bottom: -1.5rem; }
.addresses .item { margin-bottom: 1.5rem; }
.addresses .item-inner { position: relative; padding-left: 1.6rem; }
.addresses .item-inner .icon { position: absolute; left: -0.15rem; top: 0.25rem; width: 1.2rem; height: 1.2rem; color: $yellow; }

.boxes-check-list { margin-bottom: -1.5rem; }
.boxes-check-list .item { width: 50%; margin-bottom: 1.5rem; }
.boxes-check-list .item-inner { background: $lightBlue; @include foldedCorner($lightBlue, #bdd9ed); padding: 2rem 2.5rem; height: 100%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; }
.boxes-check-list .item-title { width: 100%; }
.boxes-check-list .item-content { display: flex; margin-top: 0.5rem; width: 100%; }
.boxes-check-list .item-text { flex-grow: 1; }
.boxes-check-list .item-text ul { @extend .list-checked; }
.boxes-check-list .item-img { flex: 0 0 7.5rem; padding-right: 1.5rem; text-align: center; }
.boxes-check-list .item-img img { display: inline-block; }
.boxes-check-list .btn-wrapper { margin-bottom: 0; margin-top: auto; }

/* Topics */

.topic { background: $lightBlue; padding: 2.5rem; display: flex; @include foldedCorner($lightBlue, #bdd9ed); }
.topic:not(:last-child) { margin-bottom: 1rem; }
.topic-head { flex: 0 0 30%; padding-right: 2rem; @extend .h3; margin-bottom: 0; }
.topic-content { flex: 1; }

/* Phd topics */

.item-detail { display: flex; margin-bottom: 0.3rem; }
.item-detail-title { display: inline-block; flex: 0 0 6rem; color: $gray; font-size: 0.7rem; text-transform: uppercase; padding-top: 0.2rem; padding-right: 1rem; }
.item-detail-content { flex: 1; font-size: 0.9rem; color: $blueGray; font-weight: 400; }
.item-detail-link { font-weight: 500; @extend %reverseUnderline; }
.item-detail p:last-child { margin-bottom: 0; }

/* Patents */

.item-detail-title { display: inline-block; flex: 0 0 8rem; }

/* Events */

.events .item:not(:last-child) { margin-bottom: 1rem; }
.events .item-inner { display: flex; flex-wrap: wrap; justify-content: space-between; padding: 0.8rem 1rem; text-decoration: none; @include foldedCornerHover($lightBlue, #bdd9ed, $yellow, #f1deb1); }
.events .item-inner { &:hover, &:active, &:focus { .item-title, .item-date, .item-text { color: $white; } } }
.events .item-title { flex: 2; font-size: 1.2rem; line-height: 1.4; font-weight: 500; color: $blue; margin-bottom: 0; }
.events .item-date { margin-bottom: 0; font-size: 0.9rem; line-height: 1.3; color: $gray; width: 100%; }
.events .item-labels { flex: 1; text-align: right; }
.events .item-label { display: inline-block; background: $white; color: $blue; padding: 0.25rem 0.75rem; font-size: 0.7rem; text-transform: uppercase; text-decoration: none; border-radius: 1rem; }
.events .item-label:not(:last-child) { margin-bottom: 0.25rem; }
.events .item-text { font-size: 0.9rem; color: $blueGray; margin-top: 0.15rem; width: 100%; }
.events .item-text p { margin-bottom: 0; }

.events-small .item { margin-bottom: 0.5rem; }
.events-small .item-title { font-size: 0.9rem; line-height: 1.55; }
.events-small .item-inner { padding: 0.75rem 1rem; }

.events-blocks .events { margin-top: 1.5rem; }

.filter { margin-bottom: 1.5rem; }

/* Schedule */

.schedule .item { margin-bottom: 1rem; }
.schedule .item-inner { display: flex; background: $white; border: 2px solid $borderBlue; border-radius: 3px; }
.schedule .item-date-wrap { background: $lightestBlue; padding: 0.6rem 0.75rem; flex: 0 0 33%; }
.schedule .item-date { @extend .text-line; font-size: 0.9rem; margin-bottom: 0; color: $gray; line-height: 1.4; }
.schedule .item-date::before{ top: 0.6rem; }
.schedule .item-content { padding: 0.6rem 1rem; color: $blueGray; line-height: 1.5; font-size: 0.9rem; }
.schedule .item-content p { margin-bottom: 0.1rem; }
.schedule .item-title { font-size: 0.9rem; font-weight: 500; margin-bottom: 0.2rem; color: $black; line-height: 1.4; }
.schedule .item-title:last-child { margin-bottom: 0; }

.schedule-hp .item-inner { flex-wrap: wrap; }
.schedule-hp .item-date-wrap { flex: 0 0 100%; padding: 0.3rem 0.75rem 0.3rem 0.75rem; }

/* News */

.news .item:not(:last-child) { margin-bottom: 1.75rem; }
.news.item-list .item-inner { display: flex; }
.news .item-inner { &:hover, &:active, &:focus { .item-title { text-decoration: underline; color: $yellow; } } }
.news .item-text { font-size: 0.9rem; color: $gray; }
.news .item-text p:last-child { margin-bottom: 0; }
.news .item-img { margin-right: 1.5rem; flex: 0 0 14.25rem; padding-top: 0.15rem; }
.news .item-title { color: $blue; font-size: 1.2rem; line-height: 1.33; font-weight: 500; margin-bottom: 0.65rem; }
.news .item-date { font-size: 0.9rem; color: $gray; line-height: 1.5; @extend .text-line; margin-bottom: 0.75rem; }

.news-hp .item { margin-bottom: 1.75rem; }
.news-hp .item-date { margin-bottom: 0; }
.news-hp .item-inner { flex-wrap: wrap; }
.news-hp .item-img { margin-right: 0; margin-bottom: 0.7rem; padding: 0; }
.news-hp .item-img, .news-hp .item-text { flex: 0 0 100%; }

.news-date { margin-bottom: 0.5rem; @extend .text-line; display: inline-block; margin-right: 1.5rem; font-size: 0.9rem; color: $white; &:before { top: 0.6rem; } }
.news-author { margin-bottom: 0.5rem; font-size: 0.9rem; color: $white; display: inline-block; position: relative; padding-left: 1.4rem; &:before { content: ""; display: block; width: 0.9rem; height: 0.9rem; position: absolute; left: 0; top: 0.15rem; background: url('../img/person.svg') center center no-repeat; background-size: 100% 100%; } }

/* For employees */

.general-items { margin-bottom: -1.5rem; align-items: stretch; }
.general-items .item { margin-bottom: 1.5rem; }
.general-items .item-img { position: relative; }
.general-items .item-img img { width: 100%; }
.general-items .item-inner { position: relative; background: $white; height: 100%; }
.general-items .item-title { font-size: 1.2rem; margin-bottom: 0.3rem; color: $blue !important; font-weight: 500; }
.general-items a.item-inner { &:hover, &:active, &:focus { .item-title { color: $yellow !important; text-decoration: underline; } } }
.general-items .item-text { padding: 0.75rem 1.1rem; }

/* Regular events, laboratories */

.box-items .item { width: 100%; }
.box-items .item:not(:last-child) { margin-bottom: 1rem; }
.box-items .item-inner { background: $lightBlue; display: flex; }
.box-items a.item-inner { &:hover, &:active, &:focus { .item-title { color: $yellow; text-decoration: underline; } } }
.box-items .item-img { flex: 0 0 14.25rem; }
.box-items .item-text { flex: 1; font-size: 0.9rem; color: $blueGray; padding: 2rem 2.5rem; }
.box-items .item-text { h2, h3, h4, h5, h6 { color: $baseFontColor; } }
.box-items .item-text p:last-child { margin-bottom: 0; }
.box-items .item-title, .box-items h3.item-title, .box-items .item-text h3.item-title { @extend .h3; color: $blue; margin-bottom: 1rem; }
.box-items .item-date { font-size: 0.9rem; margin-bottom: 0; color: $blueGray; text-transform: uppercase; }

.box-items .item-text { h2, h3 { @extend .h4; } }

/* About faculty */

.numbers { margin: 0 0 -1.5rem -1.5rem; }
.numbers .item { width: 16.66666%; padding-left: 1.5rem; margin-bottom: 1.5rem; }
.numbers .item-inner { background: $white; padding: 24px 18px; height: 100%; color: $blue }
.numbers a.item-inner:hover { color: $white; background: $yellow; }
.numbers .item-text { font-size: 0.9rem; margin-bottom: 0.15rem; line-height: 1.3; }
.numbers .item-suptext { display: inline-block; }
.numbers .item-number { font-size: 2.4rem; font-weight: 500; display: block; line-height: 1.2; margin: 0.5rem 0 0.25rem 0; }
.numbers .item-title { display: block; text-wrap: balance; }
.numbers .item-title svg { display: inline-block; margin-left: 0.25rem; }

/* Subjects */

.subject { display: flex; justify-content: flex-end; }
.subject-data { color: $black; width: 3rem; }
.subject-code-name { margin-right: auto; display: flex; }
.subject-code { width: 6rem; }

/* Science and research */

.column { height: 100%; display: flex; flex-direction: column; }

.applications, .galleries { margin: 0 0 0 -1.5rem; }
.applications .item { width: 33.33333%; padding-left: 1.5rem; margin-bottom: 1.5rem; }
.galleries .item { width: 50%; padding-left: 1.5rem; margin-bottom: 1.5rem; }
.applications, .galleries { .item-inner { position: relative; overflow: hidden; } }
.applications, .galleries { .item-inner:after { content: ""; display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.45) 100%); z-index: 1; } }
.applications, .galleries { .item-img { transform: scale(1); transition: all 0.1s ease-in; transform-origin: 50% 50%; } }
.applications, .galleries { .item-inner { &:hover, &:active, &:focus { .item-img { transform: scale(1.08); transition: all 0.1s ease-out; } } } }
.applications, .galleries { .item-text { position: absolute; left: 0; bottom: 0; padding: 1.5rem; max-width: 400px; z-index: 5; } }
.applications, .galleries { .item-title { margin-bottom: 0; font-size: 1.2rem; font-weight: 500; } }

.applications, .galleries { .item-no-img .item-text { position: relative; } }
.applications, .galleries { .item-no-img .item-inner { background: $lightBlue; } }
.applications, .galleries { .item-no-img .item-inner:after { display: none; } }

/* Our partners */

.logos { margin-bottom: -1.5rem; }
.logos .item { margin-bottom: 1.5rem; }
.logos .item-inner { background: $white; height: 100%; border: 2px solid $darkerBorderBlue; padding: 0.5rem 1rem; display: flex; justify-content: center; align-items: center; min-height: 9rem; transition: border-color 0.07s ease-in; }
.logos a.item-inner { &:hover, &:active, &:focus { border-color: $yellow; } }

/* Persons */

.persons-category { display: flex; }
.persons-category:not(:last-child) { margin-bottom: 5rem; }
.persons-category-name { width: 3rem; @extend .h2; }

.persons { flex: 1; }
.person { background: $lightBlue; display: flex; text-decoration: none; }
a.person:hover, a.person:active, a.person:focus { background: $yellow; .person-content, .person-name { color: $white; } .person-position:before { background: $white; } }
.person:not(:last-child) { margin-bottom: 1rem; }
.person-content { padding: 1rem 1.5rem; }
.person-name { @extend .h4; color: $blue; margin-bottom: 0.35rem; line-height: 1.2; }
.person-position { @extend .text-line; font-size: 0.7rem; text-transform: uppercase; margin-bottom: 0; }

/* Faculty history */

.timeline { position: relative; }
.timeline:before { content: ""; display: block; width: 2px; height: 100%; background: #bbdcf2; position: absolute; left: 50%; top: 0; margin-left: -1px; z-index: -1; }
.timeline-date { text-align: center; width: 100%; color: $gray; font-size: 1.5rem; font-weight: 400; background: $white; padding: 0.6rem 0; margin-bottom: 0; position: relative; z-index: 6; }
.timeline-date:not(:first-child) { margin-top: 2.25rem; }
.timeline-item { display: flex; position: relative; margin-top: -1rem; }
.timeline-date + .timeline-item { margin-top: 0; }
.timeline-item:nth-of-type(2n+1) { justify-content: flex-start; }
.timeline-item:nth-of-type(2n) { justify-content: flex-end; }
.timeline-item-text { background: $lightBlue; width: calc(50% - 4rem); padding: 2rem 2.5rem; }
.timeline-item-img { width: 11rem; height: 11rem;  position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.timeline-item-img img { position: relative; z-index: 5; border-radius: 50%; overflow: hidden; }
.timeline-item-img:after { content: ""; display: block; width: 4px; height: calc(100% + 1.5rem); background: $white; position: absolute; left: 50%; top: -0.75rem; margin-left: -2px; z-index: 2; }

/* Map */

.map-wrapper { background-color: $lightBlue; padding: 1rem; }
.map iframe { width: 100%; height: 100%; }
.map { height: 32rem; width: 100%; }
.map-contacts { padding: 1rem 1rem 1rem 1.5rem; font-size: 0.9rem; color: $gray; }
.map-contacts hr { border-top: 2px solid #d1d9e6; margin: 1.2rem 0 1rem 0; }
.map-contacts-head, p.map-contacts-head {  font-weight: 700; font-size: 1.2rem; line-height: 1.5; margin-bottom: 0.6rem; color: $black; }
.map-contacts-img { margin: 1.2rem 0 1.2em 0; }
.map-contacts .contact-list li { font-weight: 400; }

/* Social nets */

.soc-nets { margin: 0 -20px; }

/* Search results */

.search-results .item-labels { margin-bottom: 0.35rem; }
.search-highlights em { font-weight: 500; }

/* Press */

.references .item:not(:last-child) { margin-bottom: 1.75rem; }
.references .item-inner { display: flex; align-items: center; }
.references .item-inner { &:hover, &:active, &:focus { .item-title { text-decoration: underline; color: $yellow; } } }
.references .item-text { font-size: 0.9rem; color: $gray; }
.references .item-text p:last-child { margin-bottom: 0; }
.references .item-img { margin-right: 1.5rem; flex: 0 0 14.25rem; padding: 1.25rem; border: 2px solid #bbdcf2; min-height: 10rem; display: flex; justify-content: center; align-items: center; }
.references .item-title { color: $blue; font-size: 1.2rem; line-height: 1.33; font-weight: 500; margin-bottom: 0.65rem; }
.references .item-date { font-size: 0.9rem; color: $gray; line-height: 1.5; @extend .text-line; margin-bottom: 0.75rem; }

/* Pager */

.pager { padding-top: 3.5rem; text-align: center; position: relative; display: flex; justify-content: center; align-items: center; }
.pager ul { margin-bottom: 0; display: flex; }
.pager li { background: none; position: relative; padding: 0 0.25rem; font-size: 0.9rem; font-weight: 500; }
.pager li a { background-color: $white; display: inline-block; line-height: 2.5em; width: 2.5rem; height: 2.5rem; text-align: center; color: $blue; text-decoration: none; border: 2px solid $borderBlue; }
.pager li a:hover, .pager li a:active, .pager li a:focus { background: $yellow; color: $white; border-color: $yellow; }
.pager li .pager-active { border-color: $yellow; }

.pager .pager-prev, .pager .pager-next { position: absolute; margin-top: -0.25rem; }
.pager .pager-prev, .pager .pager-next { &:not(:hover) { background-color: $white; } }
.pager .pager-prev { left: 0; }
.pager .pager-next { right: 0; }

.pager .pager-first .pager-dots { display: inline-block; margin: 0 0.1rem 0 0.5rem; }
.pager .pager-last .pager-dots { display: inline-block; margin: 0 0.5rem 0 0.1rem; }
.pager .pager-counter { display: none; }

@media screen and (min-width: $xl) {
  .block-hp-banners .container {
    max-width: min(1980px, 100vw);
  }
}

@media screen and (max-width: $xl) {
	.teaser-news.news .item-title { font-size: 2.2rem; }
	.numbers .item-number { font-size: 2.2rem; }

	.teaser-bg:not(.teaser-omega) { padding-bottom: 5rem; }

	.teaser-bg-subhead { font-size: 2rem; }

	.column-head { font-size: 0.9rem; }

	.cta-head { font-size: 1.8rem; }
	.block-bg-box-title { font-size: 1.6rem; }
	.block-bg-box-number { font-size: 1.6rem; }

	.news .item-title { font-size: 1.1rem; }
	.references .item-title { font-size: 1.1rem; }
	.success-stories .item-title { font-size: 1.1rem; }
	.applications, .galleries { .item-title { font-size: 1.1rem; } }
	.accordion-title, .step-title, .step-number { font-size: 1.1rem; }
	.general-items .item-title { font-size: 1.1rem; margin: 0; }

	.block-bg-box { padding: 1.6rem 2rem 2rem 2rem; }

	.fields-box { padding: 2.5rem 3.25rem 3.25rem 3.25rem; }
	.fields-list .btn { padding: 1.25rem 5rem 1.25rem 2rem; }

	.box-items .item-text { padding: 1.75rem 2rem; }

	.numbered-block-number { font-size: 1.6rem; }
	.numbered-block-left .numbered-block-box { padding: 1.25rem 2rem 2rem 2.75rem; }
	.numbered-block-right .numbered-block-box { padding: 1.25rem 0 2rem 4.75rem; margin-left: auto; }

	.topic { padding: 2.15rem; }

	.subsite-nav-item { min-height: 2.8rem; }
}

@media screen and (max-width: $lg) {
	.teaser-news.news .item-title { font-size: 2rem; }
	.numbers .item-number { font-size: 2rem; }
	.numbers .item-inner { padding: 1rem 0.8rem; }

	.teaser-bg-subhead { font-size: 1.6rem; }

	.teaser-bg:not(.teaser-omega) { padding-bottom: 4rem; }

	.column-head { font-size: 0.9rem; }


	.cta-head { font-size: 1.6rem; }
	.block-bg-box-title { font-size: 1.6rem; }
	.block-bg-box-number { font-size: 1.6rem; }

	.news .item-title { font-size: 1rem; }
	.references .item-title { font-size: 1rem; }
	.success-stories .item-title { font-size: 1rem; }
	.applications, .galleries { .item-title { font-size: 1rem; } }
	.general-items .item-title { font-size: 1rem; margin: 0; }

	.fields-box { padding: 2rem 3rem 3rem 3rem; }
	.fields-list .btn { padding: 1rem 4rem 1rem 1.75rem; }

	.box-half-img .box-content { padding: 2.5rem 2.25rem 2.5rem 3rem; }
	.box-content { padding: 1.5rem 1.5rem 1.5rem 1.75rem; }
	.topic { padding: 2rem; }

	.box-items .item-text { padding: 1.5rem 1.75rem; }

	.numbered-block:not(.numbered-block-noimg) { min-height: 23rem; }
	.numbered-block-number { font-size: 1.6rem; }
	.numbered-block-box { width: 60%; }

	.filter { padding-left: 1rem; }

	.subsite-nav-item {  min-height: 2.6rem; }

	.general-items.item-list-3 .item { width: 50%; }

	.contact-img { flex: 0 0 13rem; }
	.contact-content { flex-wrap: wrap; }
	.contact-content-col:nth-child(1) { width: 100%; }
	.contact-content-col:nth-child(2) { width: 100%; margin-top: 1rem; }

	.boxes-check-list .item-content { flex-wrap: wrap; }
	.boxes-check-list .item-text { width: 100%; margin-top: 1rem; }
}

@media screen and (max-width: $md) {
	.teaser-news { padding-left: 0; }

	.teaser-hp { min-height: 0; padding-bottom: 0; }
	.teaser-img-overflow { position: relative; margin: 2.5rem 0 0 0; max-height: none; overflow: visible; width: 100vw; }
	.teaser-img-overflow img { min-width: 0; min-height: 0; width: 100%; }
	.teaser-img-overflow:after { left: 3.5rem; top: 0; transform: scale(0.9); transform-origin: 0 0; }
	.teaser-img-half { width: 100%; left: auto; }

	.teaser-bg:not(.teaser-omega) { padding-bottom: 3rem; }

	.teaser-bg-head { margin-right: 0; }

	.teaser-head-img { margin-top: 0; margin-bottom: 1.5rem; }
	.teaser-head-img-abs { position: relative; }

	.teaser-prev, .teaser-next { display: flex; align-items: center; margin-bottom: 1.5rem; }
	.teaser-prev .btn { margin-right: 0.75rem; margin-bottom: 0; }
	.teaser-next .btn { margin-left: 0.75rem; margin-bottom: 0; }
	.teaser-next { .btn { order: 2; } .teaser-next-text { order: 1; } }

	.teaser-details { flex-wrap: wrap; }
	.teaser-detail { flex: 0 0 33.33333%; }

	.content-shift-md { margin-top: 0; }
	.content-shift-space-md { padding-bottom: 2.8rem; }

	.item-title-hl { padding: 0.35rem 0 0.45rem 0; }
	.item-title-hl-small { padding: 0.4rem 0 0.55rem 0; }

	.news.item-list-3 .item { width: 50%; }

	.fields-list { margin-top: 2rem; }

	.block.block-bg { background: $blue; padding-top: 0; padding-bottom: 2.75rem; min-height: 0; display: block !important; }
	.block-bg-img { position: relative; width: 100%; height: auto; margin-bottom: 2.5rem; }
	.block-bg-img img { position: relative; left: 0; transform: none; }
	.block-bg-desc { transform: translateY(-100%); margin-top: -4rem; position: absolute; }
	.block-bg-hp .block-bg-desc {  }
	.block-bg-box-number { position: relative; transform: none; left: 0; margin-bottom: 1rem; }

	.block-bg .block-bg-box { padding: 0; }

	.block-bg-reasons .block-bg-box { padding-top: 0; }

  .hp-banners {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 440px 250px;
    height: auto;
    gap: 20px;
  }
  .hp-banner:first-child {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .hp-banner:nth-child(2),
  .hp-banner:nth-child(3) {
    grid-column: span 1;
    grid-row: 2;
  }

	.stories .item { width: 50%; }

	.box-half-img { flex-wrap: wrap; }
	.box-half-img .box-content { width: 100%; order: 1; }
	.box-half-img .box-img { width: 100%; order: 2; }
	.box-half-img .box-img img { position: relative; }

	.numbered-block { min-height: 0 !important; }
	.numbered-block-img { position: relative; margin-bottom: 1.5rem; margin-top: 1rem; }
	.numbered-block-box { padding: 0 0 0 3rem !important; width: 100%; }
	.numbered-block-number { line-height: 1.25; }

	.contact-content { padding: 1.25rem 1rem 1.25rem 0; }
	.contact-content-col:nth-child(1) { padding-left: 1.25rem; }
	.contact-content-col:nth-child(2) { padding-left: 1.25rem; }

	.topic { padding: 1.75rem; }

	.topics-compact.topic { flex-wrap: wrap; }
	.topics-compact .topic-head, .topic-content { flex: 0 0 100%; }
	.topics-compact .topic-head { margin-bottom: 1rem; }

	.item-detail { flex-wrap: wrap; margin-bottom: 0.5rem; }
	.item-detail-title, .item-detail-content { flex: 0 0 100%; }

	.events .item-labels, .events .item-title { flex: 0 0 100%; }
	.events .item-labels { text-align: left; margin-top: 0.25rem; }

	.filter { padding-left: 0; }

	.numbers .item { width: 33.33333%; }

	.columns { margin-bottom: -1.75rem; }
	.column { margin-bottom: 1.75rem; display: block; }

	.applications, .galleries { .item { width: 50%; } }

	.map { height: 20rem; width: 100%; }

	.boxes-check-list .item { width: 100%; }

	.timeline::before { left: 4.5rem; }
	.timeline-item { margin-top: 0 !important; }
	.timeline-item:nth-of-type(2n+1) { justify-content: flex-end; margin-bottom: 2rem; }
	.timeline-item-text { width: calc(100% - 11rem); }
	.timeline-item-img { left: 0; transform: translateY(-50%); width: 9rem; height: 9rem; }
	.timeline-date { width: 9rem; }
	.timeline-date:not(:first-child) { margin-top: 3.5rem; }
}

@media screen and (max-width: $sm) {
	.teaser-bg-subhead { font-size: 1.6rem; }

	.teaser-detail { flex: 0 0 100%; }

	.success-stories .item { width: 100%; }
	.applications, .galleries { .item { width: 100%; } }

	.cta { padding: 2rem 0; }
	.cta-content { flex-wrap: wrap; justify-content: center; text-align: center; }
	.cta-dots { width: 5; height: 2rem; border-top: none; border-left: 5px dotted $yellow; margin: 0.8rem 0 1rem 0; flex: none; }
	.cta-head, .cta-btn { width: 100%; }

	.box-half-img .box-content { padding: 2rem 2rem 2rem 2.25rem; }

	.img-set-2 figure { width: 100%; }

	.contact { flex-wrap: wrap; max-width: 300px; }
	.contact-content { padding: 1.15rem 1rem 1.15rem 0; }
	.contact-content-col:nth-child(1) { padding-left: 1.15rem; }
	.contact-content-col:nth-child(2) { padding-left: 1.15rem; }
	.contact-img { flex: 0 0 100%; }
	.contact-img img { width: 100%; }

	.topic { flex-wrap: wrap; }
	.topic-head, .topic-content { flex: 0 0 100%; }
	.topic-head { margin-bottom: 1rem; }

	.pager { flex-wrap: wrap; justify-content: center; }
	.pager ul { width: 100%; margin: 1rem 0; justify-content: center; order: 1; }
	.pager .pager-prev { position: relative; margin-right: 0.5rem; margin-bottom: 0.25rem; }
	.pager .pager-next { position: relative; margin-left: 0.5rem; margin-bottom: 0.25rem; }
	.pager li a { width: 2.25rem; height: 2.25rem; line-height: 2.25; }

	.news:not(.news-hp) .item-img { flex: 0 0 10rem; }
	.references:not(.news-hp) .item-img { flex: 0 0 10rem; }

	.box-items .item-inner { flex-wrap: wrap; }
	.box-items .item-text { padding: 1.25rem 1.5rem; }
	.box-items .item-img, .box-items .item-text { flex: 0 0 100%; }
	.box-items .item-img img { width: 100%; }

	/*.subsite-nav ul { flex-wrap: wrap; flex-direction: column; align-items: stretch; }
	.subsite-nav li { display: block; }
	li:not(:first-child) .subsite-nav-item { border-left: none; border-top: 2px solid #015895; }
	.subsite-nav-item { padding-top: 0.6rem; padding-bottom: 0.6rem; min-height: none; }*/

	.subsite-nav { width: calc(100% + 30px); position: relative; margin-left: -15px; margin-right: -15px; }
	.subsite-nav:before, .subsite-nav:after { content: ""; display: block; width: 35px; height: 100%; position: absolute; top: 0; pointer-events: none; }
	.subsite-nav:before { left: 0; background: linear-gradient(to right, rgba($black, 0.45) 0%, rgba($black, 0) 100%); display: none; }
	.subsite-nav:after { right: 0; background: linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 0.45) 100%); }
	.subsite-nav-scroll-left:before { display: block; }
	.subsite-nav ul { overflow-y: visible; overflow-x: scroll; width: 100%;  }
	.subsite-nav ul li { position: static; }
	.subsite-nav ul.dropdown-wrap-active { padding-bottom: 10px; }
	.subsite-nav .subsite-nav-dropdown-trig { position: relative; }
	.subsite-nav .subsite-nav-dropdown { top: 100%; }
	.subsite-nav .dropdown-trig-active:before { @include triangleTop(18px, 8px, $lightYellow); position: absolute; left: 2rem; bottom: -10px; }

	.numbers .item-inner { padding: 1rem 0.75rem; }

	.logos.item-list-3 .item { width: 50%; }
	.logos .item-inner { min-height: 0; padding: 0.75rem 1rem; }

	.addresses.item-list-2 .item { width: 100%; }

	.schedule .item-inner { flex-wrap: wrap; }
	.schedule .item-date-wrap { flex: 0 0 100%; padding: 0.5rem 0.75rem 0.4rem 0.75rem; }

}

@media screen and (max-width: $xs) {
	.teaser-img-overflow:after { right: 1.5rem; transform: scale(0.8); transform-origin: 0 0; }

	.article > .row:not(:first-child) { margin-top: 2rem; }

	.news .item-img img { width: 100%; }

	.fields-box { padding: 1.5rem 2rem 2rem 2rem; }
	.fields-list .btn { padding: 0.9rem 3.5rem 0.9rem 1.5rem; background-size: 1.6rem 1.3rem; background-position: right 1.2rem center; }

	.block.block-bg { padding-bottom: 2.5rem; }
	.block-bg-img { margin-bottom: 2rem; }
	.block-bg-desc { margin-top: -2rem; }

  .block-hp-banners {
    padding: 0 0;
    .container {
      padding: 0;
    }
  }
  .hp-banners {
    grid-template-columns: 1fr;
    grid-template-rows: 440px 220px 220px;
    gap: 10px;
  }
  .hp-banner:first-child {
    grid-column: 1;
    grid-row: 1;
    .hp-banner-content {
      padding: 32px 16px;
      max-width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .hp-banner:nth-child(2),
  .hp-banner:nth-child(3) {
    grid-column: 1;
    grid-row: auto;
  }
  .hp-banner-content {
    bottom: 16px;
    left: 16px;
    right: 16px;
    padding: 20px;
    max-width: 75%;
  }
  .hp-banner-title {
    font-size: 24px;
  }
  .hp-banner-description {
    font-size: 16px;
    line-height: 1.2;
  }

	.box-half-img .box-content { padding: 1.5rem 1.5rem 1.5rem 1.75rem; }
	.box-content { padding: 1.25rem 1.25rem 1.25rem 1.5rem; }

	.accordion-title, .step-title, .step-number { font-size: 1rem; }
	.step-head { padding-left: 2.75rem; }
	.accordion-head, .accordion-subhead { padding-left: 1.15rem; }
	.accordion-subhead { padding-right: 1.25rem; }
	.step-number { left: 1rem; top: 1rem; }
	.step-subhead { padding-left: 2.75rem; }
	.accordion-content { padding: 0 1.25rem 0.5rem 1.25rem; }
	.step-content { padding: 0 1.25rem 0.5rem 1.35rem; }

	.topic { padding: 1.25rem; }

	.news .item-inner { flex-wrap: wrap; }
	.news .item-img, .news .item-text { flex: 0 0 100% !important; }
	.news .item-img { margin-bottom: 0.7rem; margin-right: 0; }
	.news .item-img img { width: 100%; }

	.references .item-inner { flex-wrap: wrap; }
	.references .item-img, .references .item-text { flex: 0 0 100% !important; }
	.references .item-img { margin-bottom: 0.7rem; margin-right: 0; min-height: 0; }

	.numbers .item { width: 50% !important; }

	.timeline::before { left: 50%; }
	.timeline-item { flex-wrap: wrap; justify-content: center !important; }
	.timeline-item:not(:last-child) { margin-bottom: 3rem !important; }
	.timeline-item-text { width: 100%; order: 2; padding-top: 3rem; }
	.timeline-item-img { left: auto; transform: none; position: relative; order: 1; margin-bottom: -2rem; }
	.timeline-item-img:after { height: 100%; }
	.timeline-date { width: 100%; margin-bottom: 1.5rem; }
	.timeline-date:not(:first-child) { margin-top: 3.5rem; }
}

@media screen and (max-width: $xxs) {
}

@media screen and (max-width: 340px) {
	.block-bg-desc { margin-top: -0.5rem; }
	.pager li a { width: 2rem; height: 2rem; line-height: 2; }
}

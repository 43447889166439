%btnHover { background-color: $yellow; background-image: url('../img/arrow-white.svg'); border-color: $yellow; color: $white; }
%btnPrevHover { background-color: $yellow; border-color: $yellow; background-image: url('../img/arrow-left-white.svg'); }

.btn { font-size: 0.9rem; text-transform: uppercase; display: inline-block; vertical-align: middle; border: 2px solid $borderBlue; background-color: transparent; color: $blue; background-image: url('../img/arrow-blue.svg'); background-position: right 0.9rem center; background-repeat: no-repeat; font-weight: 500; padding: 0.45em 3.35rem 0.45rem 0.9rem; text-decoration: none; transition: all 0.07s ease-in; }
.btn:hover, .btn:active, .btn:focus { @extend %btnHover; }

.btn-left { background-image: url('../img/arrow-left-blue.svg'); background-position: left 0.9rem center; padding-left: 3.35rem; padding-right: 0.9rem; }
.btn-left:hover, .btn-left:active, .btn-left:focus { background-image: url('../img/arrow-left-white.svg'); }

.btn-sh { background-image: none !important; position: relative; padding-right: 2.5rem; }
.btn-sh:after { content: ""; display: block; width: 1.4rem; height: 1.4rem; background: url('../img/chevron-down-blue.svg') center center no-repeat; background-size: 100% 100%; position: absolute; right: 0.6rem; top: 50%; transform: translateY(-50%); transition: transform 0.07s ease-in; }
.btn-sh:hover:after, .btn-sh:active:after, .btn-sh:active:focus { background-image: url('../img/chevron-down-white.svg'); }
.btn-sh.active:after { transform: translateY(-50%) rotateX(180deg); }

.btn-light { border-color: rgba($white, 0.4); color: $white; background-image: url('../img/arrow-white.svg'); }

.btn-fill { background-color: $blue; color: $white; border-color: $blue; background-image: url('../img/arrow-white.svg'); }
.btn-fill-light { background-color: $white; color: $blue; border-color: $white; background-image: url('../img/arrow-blue.svg'); }

.btn-fill-search { @extend .btn-fill; background-image: url('../img/search-white.svg'); background-size: 1.2rem 1.2rem; font-weight: 500; padding-right: 2.75rem; }
.btn-fill-search:hover, .btn-search:active, .btn-search:focus { background-image: url('../img/search-white.svg'); }

.btn-compact { padding-right: 2.8rem; }
.btn-compact.btn-left { padding-right: 0.9rem; padding-left: 2.8rem; }

.btn-simple, .btn-simple:hover, .btn-simple:active, .btn-simple:focus { background-image: none; padding-right: 0.9rem !important; }

.btn-icon { @extend .btn-simple; }
.btn-icon .icon { margin-top: 0.3rem; width: 0.9rem; height: 0.9rem; }

.btn-prev, .btn-next { height: 2.5rem; width: 2.5rem; background-position: 50% 50%; padding: 0.5rem; }
.btn-prev, .btn-next { height: 2.5rem; width: 2.5rem; background-position: 50% 50%; padding: 0.5rem; }
.btn-prev:hover, .btn-prev:active, .btn-prev:focus { @extend %btnPrevHover; }
.teaser .btn-prev { background-image: url('../img/arrow-left-white.svg'); &:hover, &:active { background-image: url('../img/arrow-left-white.svg'); } }
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700&display=swap&subset=latin-ext');

$baseFont: 'Roboto', sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.65;
$baseFontWeight: 400;

$baseFontColor: #000;
$headingColor: #000;

$white: #fff;
$black: #1d1d1b;
$gray: #676d79;
$lightGray: #d1d9e6;
$blue: #0071bb;
$teaserBlue: #0070ba;
$lightBlue: #e8f5fc;
$lightestBlue: #f2fafe;
$lightTextBlue: #b0d2e9;
$borderBlue: #bfdbee;
$lighterBlue: #067bcb;
$darkerBlue: #0065a9;
$darkBlue: #04385a;
$darkerBorderBlue: #bbdcf2;
$darkBorderBlue: #015895;
$blueGray: #585858;
$yellow: #f0ab00;
$lightYellow: #fceecc;
$yellowBlack: #575144;
$red: #d2142a;

$hoverTransition: all 0.07s ease-in;
$bgHoverTransition: background 0.07s ease-in;

$xl: 1370px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$xxs: 420px;
a { color: inherit; text-decoration: underline; transition: color 0.07s ease-in; }
a:hover, a:active, a:focus { text-decoration: none; color: inherit; }

%reverseUnderline { text-decoration: none; &:hover, &:active, &:focus { text-decoration: underline; } }
a.reverse-underline { @extend %reverseUnderline; }

/* Heading */

h1, .h1 { font-weight: 700; font-size: 2.4rem; line-height: 1.25; margin: 0 0 0.4em 0; }
h2, .h2 { font-weight: 700; color: $headingColor; font-size: 1.8rem; line-height: 1.3; margin: 0 0 0.6em 0; }
h3, .h3 { font-weight: 700; font-size: 1.4rem; line-height: 1.3; margin: 0 0 0.75em 0; }
h4, .h4 { font-weight: 700; font-size: 1.2rem; line-height: 1.5; margin: 0 0 0.6em 0; }
h5, .h5 { font-weight: 700; font-size: 1rem; line-height: 1.5; margin: 0 0 1em 0; }
h6, .h6 { font-weight: 400; font-size: 1rem; line-height: 1.5; margin: 0 0 1em 0; }

.suphead { font-size: 2rem; font-weight: 300; margin-bottom: 0; line-height: 1.3; }
.subhead { font-size: 2rem; font-weight: 300; margin-bottom: 0.2em; line-height: 1.3; }

/* Paragraphs */

p { margin: 0 0 1em 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 1em 0; }
ul li { margin: 0 0 0.25em 0; padding: 0 0 0 2.7em; }
.list-unstyled { li { padding: 0; &:before { display: none !important; } } }

.list-checked { li { position: relative; margin-bottom: 0.75rem; padding: 0 0 0 1.5rem; &:before { content: ""; display: block; width: 1.2rem; height: 1.2rem; background: url('../img/check-circle.svg') center center no-repeat; background-size: 100% 100%; position: absolute; left: 0; top: 0.1rem; } } }

ol { counter-reset: counter; margin-left: 0; *margin-left: 54px; }
ol > li { margin: 0 0 0.5em 0; padding: 0 0 0 2.7em; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol > li:before { color: $yellow; font-weight: 500; content: counter(counter)'. '; position: absolute; left: 1rem; top: 0; }

dl { margin: 0 0 1em 0; }
dt { font-weight: bold; }

.text-hl { font-weight: 500; color: $blue; }

.text-small { font-size: 0.7em; }
.text-big { font-size: 1.3em; }

.text-light { font-weight: 300; }
.text-normal { font-weight: 400; }
.text-bold { font-weight: 500; }
strong { font-weight: 500; }

.text-uppercase { text-transform: uppercase; }
.text-nowrap { white-space: nowrap; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.color-gray { color: $gray !important; }
.color-blue { color: $blue !important; }
.color-yellow { color: $yellow !important; }
.color-black { color: $black !important; }
.color-white { color: $white !important; }

blockquote { background: $lightBlue; color: $blue; padding: 1.5rem 2rem 2rem 5rem; font-size: 1.2rem; line-height: 1.35; position: relative; }
blockquote p, .entry blockquote p { font-size: 1.2rem; line-height: 1.35; }
blockquote:before { content: "“"; display: block; font-size: 6rem; line-height: 1; color: rgba($blue, 0.3); position: absolute; top: 0.8rem; left: 1.75rem; }
blockquote:after { @include triangleTopRight(1.5rem, 1.5rem, $lightBlue); position: absolute; left: -1.5rem; top: 0; }
blockquote:not(:Last-child) { margin-bottom: 1.5rem; }
blockquote:not(:first-child) { margin-top: 1.5rem; }

.text-line { position: relative; padding-left: 1.35rem; &:before { content: ""; display: block; width: 1rem; height: 2px; background: $yellow; position: absolute; left: 0; top: 0.8em; } }
span.text-line { display: inline-block; }

.f-l { float: left; }
.f-r { float: right; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid $gray; margin: 1em 0; padding: 0; }

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

.perex, .perex.entry { font-size: 1.2rem; line-height: 1.5; p, ul, ol { font-size: 1.2rem; line-height: 1.5; } }
.teaser .perex { color: $lightBlue; }

.entry { p, ul, ol { font-size: 0.9rem; margin-bottom: 1.15rem; } }
.entry a:not(.btn):not(.link) { color: $blue; }
.entry p, .entry ul, .entry ol, .entry li { &:last-child { margin-bottom: 0.25rem; } }
.entry p .btn { margin-top: 0.4rem; margin-bottom: 0.4rem; }
.entry p .btn:not(:last-of-type) { margin-right: 0.75rem; }
.entry h2 { margin-top: 1.5rem; }
.entry h3 { margin-top: 1.5rem; }
.entry h4 { margin-top: 1.5rem; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry h4:first-child { margin-top: 0; }
.entry ul li { position: relative; }
.entry ul li:before { content: ''; position: absolute; left: 1.15rem; top: 0.55rem; width: 10px; height: 10px; display: block; background: $yellow; }

figcaption { width: 100%; background: $lightYellow; padding: 0.6rem 1rem 0.75rem 1rem; font-size: 0.8rem; color: $yellowBlack; line-height: 1.5; position: relative; }
figcaption:after { @include triangleTop(1.2rem, 0.6rem, $lightYellow); position: absolute; top: -0.6rem; left: 1.5rem; }

/* laptop < 1370 */
@media screen and (max-width: $xl) {
	h1, .h1 { font-size: 2.2rem; }
	h2, .h2 { font-size: 1.6rem; }
	h3, .h3 { font-size: 1.4rem; }

	.perex, .perex.entry { font-size: 1.1rem; p, ul, ol { font-size: 1.1rem; } }
	blockquote, blockquote p, .entry blockquote p { font-size: 1.1rem; }

	blockquote { padding: 1.25rem 1.75rem 1.75rem 4.75rem; }
	blockquote:before { left: 1.5rem; top: 0.5rem; }
}

@media screen and (max-width: $lg) {
	h1, .h1 { font-size: 2rem; }
	h2, .h2 { font-size: 1.6rem; }
	h3, .h3 { font-size: 1.2rem; }

	.perex, .perex.entry { font-size: 1rem; p, ul, ol { font-size: 1rem; } }
	blockquote, blockquote p, .entry blockquote p { font-size: 1rem; }

	blockquote { padding: 1.15rem 1.5rem 1.15rem 4rem; }
	blockquote:before { left: 1.25rem; top: 0.35rem; font-size: 5rem; }
}

@media screen and (max-width: $md) {
}

/* mobile < 768 */
@media screen and (max-width: $sm) {

}

@media screen and (max-width: $xs) {
//	h1, .h1 { font-size: 2rem; }
	.item-list .item { width: 100% !important; }
}

@media screen and (max-width: $xxs) {

}